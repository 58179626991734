import * as React from "react"
import BBSLogo from './bbsjl.jpg';

const Logo = () => (
  <div>
  <img src={BBSLogo} alt="BBS Logo" />
</div>
)

export default Logo
